import axios from 'axios'

// create an axios instance
const service = axios.create({
  baseURL: 'https://fwm2java.quwen.xyz/community/' // url = base url + request url
  // baseURL: 'https://web.hznm.shop/community/' // url = base url + request url
  // baseURL: 'http://36.138.25.83:8085/community/' // url = base url + request url
  // baseURL: 'http://192.168.1.184:8085/community/' // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 2000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data

    // 当status返回为200时弹出错误提示
    return res
  },
  (error) => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
