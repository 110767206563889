import request from "@/api/request";
// 登录
export function wechatShareConfig(data) {
    return request({
        url: "wx/mini/wechatShareConfig",
        method: "post",
        data,
    });
}

export const getAccessToken = (params) => {
    return request({
        method: "get",
        url: "cgi-bin/token",
        params,
    });
};