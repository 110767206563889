import Vue from 'vue'
const files = require.context('@/api', true, /\.api\.js$/)
const generators = files.keys().map(key => files(key))

var obj = {}
generators.map(item => {
    Object.assign(obj, item)
})
const api = {
    ...obj
}

Vue.prototype.$api = api

export default api